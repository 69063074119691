import { styled } from "@mui/material";

//nav-height: 60px
//footer-height: 160px

export const MainContainer = styled("div")`
  width: 100%;
  color: #ffb310;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
  )}
`;
