import { Analytics, Description, PhoneIphone } from "@mui/icons-material";
import { ContentItem } from "./types";

export const contentData: ContentItem[] = [
    {
      id: 1,
      title: "Platform Dashboard",
      subtitle: "Actionable Project Insights & Analytics",
      description: "Gain real-time visibility into your design and construction workflow. With accurate, up-to-date project data and intuitive analysis tools, you can make informed decisions to stay on schedule and within budget.",
      url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FHowItWorks%2F1.png?alt=media&token=76ea7d6c-3835-4475-afb6-79b50ebc3eff",
      icon: Analytics,
    },
    {
      id: 2,
      title: "Customizable Capture Reports",
      subtitle: "Built for Your Workflow",
      description: "Streamline your data collection with forms tailored to your unique project needs. Capture the information that matters most—efficiently, accurately, and seamlessly integrate it into your CAD and BIM processes.",
      url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FHowItWorks%2F2.png?alt=media&token=f6021d0d-d2a5-4b97-9b39-c54aa95a5edd",
      icon: Description
    },
    {
      id: 3,
      title: "Mobile App",
      subtitle: "Stay connected anywhere, anytime",
      description: "Stay in control of your project info, reports, and data on any device, at any time. Our mobile app ensures you're always connected, no matter where you are.",
      url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FHowItWorks%2F3.png?alt=media&token=083b04ce-3b1e-478f-8f7a-eb9831c5493b",
      isNarrow: true,
      icon: PhoneIphone,

    },
  ];