import React from "react";
import { MainContainer } from "./styles";
import { Hero } from "./components/Hero/Hero";
import ClientCarousel from "./components/ClientCarousel/ClientCarousel";
import VideoSection from "./components/VideoSection/VideoSection";
import HowItWorks from "./components/HowItWorksSection/HowItWorks";
import StatsSection from "./components/StatsSection/StatsSection";
import LandingTestimonials from "./components/LandingTestimonials/LandingTestimonials";
import IndustrySpaces from "./components/IndustriesWeServe/IndustriesWeServe";
import OldHero from "./components/OldHero/OldHero";

export default function LandingPage() {
  return (
    <MainContainer>
      {/* <Hero /> */}
      <OldHero />
      <ClientCarousel />
      <VideoSection />
      <HowItWorks />
      <StatsSection />
      <LandingTestimonials />
      <IndustrySpaces />
    </MainContainer>
  );
}
