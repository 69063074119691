import React from "react";
import {
  CarouselContainer,
  CarouselWrapper,
  Container,
  LogoCard,
  StyledImage,
  LogosSlide,
  Title,
} from "./styles";
import { logos } from "./logos";

const ClientCarousel: React.FC = () => {
  if (logos.length === 0) return null;

  return (
    <CarouselWrapper>
      <Container>
        <Title>Powering the Future of Retail Construction and Design</Title>
        <CarouselContainer>
          {/* First set of logos */}
          <LogosSlide>
            {logos.map((logo, index) => (
              <LogoCard key={`original-${logo.id}-${index}`}>
                <StyledImage>
                  <img
                    src={logo.url}
                    alt={logo.alt}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </StyledImage>
              </LogoCard>
            ))}
          </LogosSlide>
          {/* Cloned set of logos */}
          <LogosSlide>
            {logos.map((logo, index) => (
              <LogoCard key={`clone-${logo.id}-${index}`}>
                <StyledImage>
                  <img
                    src={logo.url}
                    alt={logo.alt}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </StyledImage>
              </LogoCard>
            ))}
          </LogosSlide>
        </CarouselContainer>
      </Container>
    </CarouselWrapper>
  );
};

export default ClientCarousel;
