import React, { useState, useEffect } from "react";
import VisiblitySensor from "react-visibility-sensor";
import { CounterValue } from "./styles";

const COUNT = 100000000;

const Counter: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let increaseCount: NodeJS.Timer | undefined = undefined;

    if (isVisible && !count) {
      increaseCount = setInterval(() => {
        setCount((count) => Math.min(Math.floor(count + COUNT / 17), COUNT));
      }, 124);
    }

    if (increaseCount && count === COUNT) {
      clearInterval(increaseCount);
    }

    return () => {
      if (increaseCount && count === COUNT) {
        clearInterval(increaseCount);
      }
    };
  }, [isVisible, count]);

  return (
    <VisiblitySensor
      onChange={(isVisible: boolean | ((prevState: boolean) => boolean)) =>
        setIsVisible(isVisible)
      }
    >
      <CounterValue>
        <span>{Number(count).toLocaleString()}</span>+ ft<sup>2</sup>
      </CounterValue>
    </VisiblitySensor>
  );
};

export default Counter;
