import { styled } from "@mui/material";

export const SectionWrapper = styled("section")`
  width: 100%;
  padding: 4rem 2rem;
  background: #f5f5f5;
`;

export const Container = styled("div")`
  max-width: 80vw;
  margin: 0 auto;
`;

export const BigHeader = styled("span")`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  color: black;
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const SectionHeader = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  gap: 1rem;

  h4 {
    color: #fda136;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 800px;
  }
`;

export const ContentWrapper = styled("div")`
  display: flex;
  gap: 4rem;

  @media (max-width: 968px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const LeftColumn = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 300px;
  flex-shrink: 0;

  @media (max-width: 968px) {
    width: 100%;
  }
`;

export const RightColumn = styled("div")`
  flex: 1;
`;

export const FeatureButton = styled("button")<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: ${(props) => (props.active ? "#fff" : "transparent")};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  box-shadow: ${(props) =>
    props.active ? "0 2px 4px rgba(0,0,0,0.1)" : "none"};
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
  }
`;

export const IconWrapper = styled("div")`
  width: 48px;
  height: 48px;
  background: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    color: #333;
  }
`;

export const ButtonContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentDisplay = styled("div")<{ isNarrow?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isNarrow ? "row" : "column")};
  gap: 1.5rem;
  align-items: ${(props) => (props.isNarrow ? "center" : "flex-start")};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled("div")<{ isNarrow?: boolean }>`
  width: ${(props) => (props.isNarrow ? "50%" : "100%")};
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Description = styled("p")<{ isNarrow?: boolean }>`
  font-size: 1.75rem;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
  color: black;
  font-weight: 400;
  flex: ${(props) => (props.isNarrow ? "1" : "auto")};
`;

export const ItemTitle = styled("span")`
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffb310;
`;

export const ItemSubtitle = styled("span")`
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
`;
