import { styled } from "@mui/material";

export const VideoSectionWrapper = styled("section")`
  display: flex;
  background: linear-gradient(to bottom, #232323, #bababa);
  padding: 4rem 2rem;
  width: 100%;
  font-family: "Inter", sans-serif;
`;

export const VideoContainer = styled("div")`
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  gap: 4rem;
  align-items: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 968px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const VideoFrame = styled("div")`
  flex: 1;
  width: 50%;
  position: relative;
  border-radius: 8px;

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }

  @media (max-width: 968px) {
    width: 100%;
  }
`;

export const ContentContainer = styled("div")`
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 400px; // Ensure enough height for content
  color: white;

  @media (max-width: 968px) {
    width: 100%;
    min-height: auto;
  }
`;

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SmallHeader = styled("span")`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffb310;
`;

export const BigHeader = styled("span")`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const TextBox = styled("p")`
  font-size: 1.5rem;
  line-height: 1.6;
  color: #f5f5f5;
  margin-top: 0.5rem;
`;

export const ButtonWrapper = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 968px) {
    position: relative;
    margin-top: 2rem;
  }
`;
