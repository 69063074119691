import { styled } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";

export const TestimonialsWrapper = styled("section")`
  width: 100%;
  padding: 6rem 2rem 8rem; // Increased bottom padding
  background: white;
  overflow: visible; // Ensure content isn't cut off

  ${breakpoint(
    "small-",
    `
    display: none;
  `
  )}
`;

export const Container = styled("div")`
  max-width: 80vw;
  margin: 0 auto;
  position: relative; // For proper stacking context
`;

export const CounterSection = styled("div")`
  text-align: center;
  margin-bottom: 4rem;
`;

export const CounterHeader = styled("h3")`
  font-size: 2rem;
  color: black;
  font-family: "Inter", sans-serif;
  margin-bottom: 1rem;
`;

export const CounterValue = styled("div")`
  font-size: 5rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #fda136;

  sup {
    font-size: 2rem;
    top: -1.5em;
  }

  ${breakpoint(
    "small-",
    `
  font-size: 3rem;
        `
  )}
`;

export const TestimonialsSection = styled("div")`
  position: relative;
  padding-top: 2rem;
  margin-bottom: 10rem; // Add margin to prevent cutoff

  // Style the carousel navigation arrows
  .slick-prev,
  .slick-next {
    width: 20px; // Smaller arrow size
    height: 20px; // Smaller arrow size
    z-index: 2;

    &:before {
      font-size: 32px; // Smaller arrow size
    }
  }

  .slick-prev {
    left: -40px; // Adjust position

    @media (max-width: 1200px) {
      left: -30px;
    }
  }

  .slick-next {
    right: -40px; // Adjust position

    @media (max-width: 1200px) {
      right: -30px;
    }
  }
`;

export const CarouselContainer = styled("div")`
  max-width: 1000px;
  margin: 0 auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 3rem;
  position: relative;
  margin-bottom: 3rem;

  // Ensure content doesn't get cut off
  overflow: visible;
`;

export const TestimonialItem = styled("div")`
  text-align: center;
`;

export const TestimonialQuote = styled("p")`
  font-size: 1.5rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 2rem;
  font-style: italic;
  padding: 0 5rem;
`;

export const TestimonialAuthor = styled("div")`
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  gap: 5px;
  p {
    margin: 0;
    color: black;
    font-weight: 400;

    &:first-of-type {
      font-weight: 600;
      color: black;
    }

    &:nth-of-type(2) {
      // Company name
      color: #ffb310;
      font-weight: 500;
    }

    &:last-of-type {
      // Title/position
      color: #black;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.85rem;
    }
  }
`;
