import React from "react";
import { StyledButton } from "./styles";
import { ReusableButtonProps } from "./types";

const ReusableButton: React.FC<ReusableButtonProps> = ({
  text,
  icon: Icon,
  ...props
}) => {
  return (
    <StyledButton {...props} to={"/contact"}>
      {text}
      {Icon && <Icon />}
    </StyledButton>
  );
};

export default ReusableButton;
