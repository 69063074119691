import { styled } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";

export const StatsWrapper = styled("section")`
  width: 100%;
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%);
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        circle at 20% 150%,
        rgba(253, 161, 54, 0.15) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 80% -50%,
        rgba(253, 161, 54, 0.1) 0%,
        transparent 50%
      );
    pointer-events: none;
  }
`;

export const Container = styled("div")`
  max-width: 80vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatsCard = styled("div")`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(149, 149, 149, 0.7);
  width: 30%;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${breakpoint(
    "small-",
    `
     width: 80%;
      `
  )}
`;

export const Header = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  text-align: center;

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    font-size: 1.125rem;
  }
`;

export const StatsList = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const StatItem = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const IconWrapper = styled("div")`
  margin-bottom: 0.5rem;

  .MuiSvgIcon-root {
    color: black;
    font-size: 4rem;
  }
`;

export const StatValue = styled("div")`
  font-size: 4rem;
  font-weight: 600;
  color: #ffb310;
`;

export const StatLabel = styled("div")`
  color: black;
  font-size: 1.5rem;
`;

export const ButtonWrapper = styled("div")`
  margin-top: 4rem;
`;

export const SmallHeader = styled("span")`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fda136;
`;

export const BigHeader = styled("span")`
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  color: #f6f6f6;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;
