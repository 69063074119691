import React, { useEffect, useState } from "react";
import { contentData } from "./content";
import {
  SectionWrapper,
  Container,
  SectionHeader,
  ContentWrapper,
  LeftColumn,
  RightColumn,
  FeatureButton,
  IconWrapper,
  ButtonContent,
  ContentDisplay,
  ImageWrapper,
  Description,
  BigHeader,
  ItemTitle,
  ItemSubtitle,
} from "./styles";
import ReusableButton from "../Button/ReusableButton";
import { ArrowForward } from "@mui/icons-material";
import { SmallHeader } from "../VideoSection/styles";
import AutoplayVideo from "../AutoplayVideo/AutoplayVideo";

const HowItWorks: React.FC = () => {
  const [activeId, setActiveId] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const activeContent = contentData.find((item) => item.id === activeId);

  const videoComponent = <AutoplayVideo />;
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SmallHeader>HOW IT WORKS</SmallHeader>
          <BigHeader>
            Building intelligence that works as fast as you do
          </BigHeader>
        </SectionHeader>

        <ContentWrapper>
          <LeftColumn>
            {contentData.map((item) => (
              <FeatureButton
                key={item.id}
                active={activeId === item.id}
                onClick={() => setActiveId(item.id)}
              >
                <IconWrapper>
                  <item.icon />
                </IconWrapper>
                <ButtonContent>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemSubtitle>{item.subtitle}</ItemSubtitle>
                </ButtonContent>
              </FeatureButton>
            ))}
            <ReusableButton
              text="Find out how we can help"
              icon={ArrowForward}
            />
            {!isMobile && videoComponent}
          </LeftColumn>

          <RightColumn>
            {activeContent && (
              <ContentDisplay isNarrow={activeContent.isNarrow}>
                <ImageWrapper isNarrow={activeContent.isNarrow}>
                  <img src={activeContent.url} alt={activeContent.title} />
                </ImageWrapper>
                <Description isNarrow={activeContent.isNarrow}>
                  {activeContent.description}
                </Description>
              </ContentDisplay>
            )}
            {isMobile && videoComponent}
          </RightColumn>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default HowItWorks;
