import { styled } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";

//nav-height: 60px
//footer-height: 160px

export const MainContainer = styled("div")`
  width: 100%;
  height: calc(100vh - 60px - 165px); // Adjust for header and footer
  color: #ffb310;
  padding: 0;
  margin-top: 60px; // Header height
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(to bottom, #000000 90%, #161616);

  ${breakpoint(
    "medium-",
    `
    padding: 0 3vw;
    height: calc(100svh - 60px);
  `
  )}
`;

export const Subtext = styled("span")`
  font-family: Helvetica;
  color: #f6f6f6;
  font-size: 0.8vw;
  width: 100%;
  padding-left: 5px;
  padding-top: 2vh;
`;

export const DesktopContent = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;

  ${breakpoint(
    "medium-",
    `
    display: none;
  `
  )}
`;

export const ContentWrapper = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LeftSide = styled("div")`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
`;

export const RightSide = styled("div")`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const LeftContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2vh;
`;

export const MottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderText = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 2.5vw;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  ${breakpoint(
    "medium-",
    `
      font-size: 3.5vw;
    `
  )}
  ${breakpoint(
    "small-",
    `
  font-size: 5.5vw;
`
  )}
    ${breakpoint(
    "xxlarge+",
    `
  font-size: 2.5vw;
`
  )};
`;

export const ConveyorBeltImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: calc(
    100vh - 60px - 165px - 40px
  ); // viewport height - nav - footer - padding
`;
export const MobileImg = styled("img")`
  max-width: 100%;
  object-fit: contain;
`;

export const MobileContent = styled("div")`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 90%;
  max-width: 100%;

  ${breakpoint(
    "medium-",
    `
    display: flex;
  `
  )}
`;

export const MobileMottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  color: white;
`;

export const MobileGifContainer = styled("div")`
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 75%;
  align-items: center;
  width: fit-content;
`;

export const MobileButtonContainer = styled("div")`
  display: flex;
  padding: 10px;
  gap: 2vw;
  flex-shrink: 1;

  > a > img {
    height: 5vh;
  }
`;
