import { styled } from "@mui/material";

export const SectionWrapper = styled("section")`
  width: 100%;
  padding: 6rem 2rem;
  background: #f5f5f5;
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  margin: 0 auto;
  text-align: center;
  font-family: "Inter", sans-serif;
  justify-content: center;
  align-items: center;
`;

export const SmallHeader = styled("h2")`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffb310;
  font-family: "Inter", sans-serif;
`;

export const Header = styled("span")`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  color: black;
  font-family: "Inter", sans-serif;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const Subheader = styled("p")`
  font-size: 1.5rem;
  color: black;
  font-weight: 400;
  margin-bottom: 3rem;
  font-family: "Inter", sans-serif;
`;

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 3rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const IndustryCard = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.5rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

export const IconWrapper = styled("div")`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Title = styled("p")`
  font-size: 1.25rem;
  color: black;
  font-weight: 400;
  font-family: "Inter", sans-serif;
`;

export const Footer = styled("p")`
  font-size: 1.25rem;
  color: black;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`;
