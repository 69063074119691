import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import VisiblitySensor from "react-visibility-sensor";
import {
  TestimonialsWrapper,
  Container,
  CounterSection,
  CounterHeader,
  TestimonialsSection,
  CarouselContainer,
  TestimonialItem,
  TestimonialQuote,
  TestimonialAuthor,
} from "./styles";
import Counter from "./Counter";
import CustomCarousel from "../../../testimonials/CustomCarousel/CustomCarousel";
import { Testimonial, TestimonialLogo } from "./types";

const LandingTestimonials: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AllTestimonials {
      allTestimonialsJson {
        nodes {
          id
          copy
          source
          src
        }
      }
    }
  `);

  const testimonials: Testimonial[] = data.allTestimonialsJson.nodes.map(
    ({ copy, source, id }: { copy: string; source: string[]; id: string }) => ({
      message: copy,
      author: source,
      id,
    })
  );

  const testimonialLogos: TestimonialLogo[] =
    data.allTestimonialsJson.nodes.map(
      ({ src, id }: { src: string; id: string }) => ({
        src,
        id,
      })
    );

  const [isVisible, setIsVisible] = useState(false);

  return (
    <TestimonialsWrapper>
      <Container>
        <CounterSection>
          <CounterHeader>We've digitized</CounterHeader>
          <Counter />
        </CounterSection>

        <TestimonialsSection>
          <VisiblitySensor
            onChange={(
              isVisible: boolean | ((prevState: boolean) => boolean)
            ) => setIsVisible(isVisible)}
          >
            <CarouselContainer>
              <CustomCarousel logo={testimonialLogos}>
                {testimonials.map(({ message, author, id }: Testimonial) => (
                  <TestimonialItem key={id}>
                    <TestimonialQuote>{`"${message}"`}</TestimonialQuote>
                    <TestimonialAuthor>
                      {author.map((text, i) => (
                        <p key={i}>{i === 0 ? `- ${text}` : text}</p>
                      ))}
                    </TestimonialAuthor>
                  </TestimonialItem>
                ))}
              </CustomCarousel>
            </CarouselContainer>
          </VisiblitySensor>
        </TestimonialsSection>
      </Container>
    </TestimonialsWrapper>
  );
};

export default LandingTestimonials;
