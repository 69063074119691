import React from "react";
import {
  ContentWrapper,
  ConveyorBeltImg,
  DesktopContent,
  HeaderText,
  LeftContentWrapper,
  LeftSide,
  MainContainer,
  MobileButtonContainer,
  MobileContent,
  MobileGifContainer,
  MobileImg,
  MobileMottoContainer,
  MottoContainer,
  RightSide,
  Subtext,
} from "./styles";
import Google from "../../../../assets/images/Google.png";
import Apple from "../../../../assets/images/Apple.png";
import LandingPageGraphic from "../../assets/LandingPageGraphic.png";
import ContactForm from "../../../contact/ContactForm";
import EmailSignUp from "../EmailSignUp/EmailSignUp";

export default function OldHero() {
  return (
    <MainContainer>
      {/* Desktop View */}
      <DesktopContent>
        <ContentWrapper>
          <LeftSide>
            <LeftContentWrapper>
              <MottoContainer>
                <HeaderText>Connecting the world</HeaderText>
                <HeaderText>to building information</HeaderText>
                <Subtext>
                  Have a project in mind or would like to connect? Shoot us a
                  message.
                </Subtext>
              </MottoContainer>
              <ContactForm isLandingPage={true} />
            </LeftContentWrapper>
          </LeftSide>
          <RightSide>
            <ConveyorBeltImg
              src={
                "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FMobileApp%2FupdatedMobileGraphic.png?alt=media&token=fd993913-4229-48d2-b398-35d64fea1fb3"
              }
              alt="Landing Page Graphic"
            />
          </RightSide>
        </ContentWrapper>
      </DesktopContent>

      {/* Mobile View */}
      <MobileContent>
        <MobileMottoContainer>
          <HeaderText>Connecting the World</HeaderText>
          <HeaderText>to Building Information</HeaderText>
        </MobileMottoContainer>

        <MobileGifContainer>
          <MobileImg src={LandingPageGraphic} alt="Landing Page Graphic" />
        </MobileGifContainer>
        <EmailSignUp />

        <MobileButtonContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
          >
            <img src={Apple} alt="download on app store" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
          >
            <img src={Google} alt="download on play store" />
          </a>
        </MobileButtonContainer>
      </MobileContent>
    </MainContainer>
  );
}
