import React from "react";
import {
  VideoSectionWrapper,
  VideoContainer,
  VideoFrame,
  ContentContainer,
  SmallHeader,
  BigHeader,
  TextBox,
  ContentWrapper,
  ButtonWrapper,
} from "./styles";
import ReusableButton from "../Button/ReusableButton";
import { ArrowForward } from "@mui/icons-material";

const VideoSection: React.FC = () => {
  return (
    <VideoSectionWrapper>
      <VideoContainer>
        <VideoFrame>
          <iframe
            src={
              "https://www.youtube.com/embed/65zcAQvMH28?si=g6ABP2UH-HmBuhcq"
            }
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoFrame>
        <ContentContainer>
          <ContentWrapper>
            <SmallHeader>Accelerate Your Design Workflow</SmallHeader>
            <BigHeader>
              Streamline Your Design Process with Precision Scanning & Data
              Capture
            </BigHeader>
            <TextBox>
              Leverage our cutting-edge 3D laser scanning and customizable
              capture form solutions to simplify the way you design and build.
              Fast, accurate, and seamless—so you can spend less time on the
              details and more time bringing your vision to life.
            </TextBox>
          </ContentWrapper>
          <ButtonWrapper>
            <ReusableButton
              text="Get in touch with us today"
              icon={ArrowForward}
            />
          </ButtonWrapper>
        </ContentContainer>
      </VideoContainer>
    </VideoSectionWrapper>
  );
};

export default VideoSection;
