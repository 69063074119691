import { styled, keyframes } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";

const slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const CarouselWrapper = styled("div")`
  width: 100%;
  background: linear-gradient(to bottom, #161616, #232323);
  padding: 2rem 0;
`;

export const Container = styled("div")`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled("span")`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  color: #ffb310;

  ${breakpoint(
    "small-",
    `
        font-size: 11px;

    `
  )}
`;

export const CarouselContainer = styled("div")`
  position: relative;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(35, 35, 35, 0), #161616);
  }

  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(35, 35, 35, 0), #232323);
  }

  ${breakpoint(
    "small-",
    `
 &:before {
    left: 0;
    background: transparent;
  }

  &:after {
    right: 0;
    background: transparent;
  }
    `
  )}
`;

export const LogosSlide = styled("div")`
  display: inline-block;
  animation: ${slide} 35s infinite linear;
`;

export const LogoCard = styled("div")`
  display: inline-block;
  width: 12rem;
  height: 6rem;
  margin: 0 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`;

export const StyledImage = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;
