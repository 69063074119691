import React from "react";
import {
  SectionWrapper,
  Container,
  Header,
  Subheader,
  Grid,
  IndustryCard,
  IconWrapper,
  Title,
  SmallHeader,
  Footer,
} from "./styles";
import { ArrowForward } from "@mui/icons-material";
import ReusableButton from "../Button/ReusableButton";
import { industrySpaces } from "./industrySpaces";

const IndustrySpaces: React.FC = () => {
  return (
    <SectionWrapper>
      <Container>
        <SmallHeader>Industries We Serve</SmallHeader>
        <Header>Precision Scanning for Every Space</Header>
        <Subheader>
          From retail to residential, we deliver accurate data capture solutions
          tailored to your unique needs.
        </Subheader>

        <Grid>
          {industrySpaces.map(({ id, title, icon }) => (
            <IndustryCard key={id}>
              <IconWrapper>
                <img src={icon} alt={title} />
              </IconWrapper>
              <Title>{title}</Title>
            </IndustryCard>
          ))}
        </Grid>
        <Footer>
          Don’t let data capture or design complexity hold you back.
        </Footer>
        <ReusableButton
          text="Let us help today"
          icon={ArrowForward}
          to="/contact"
        />
      </Container>
    </SectionWrapper>
  );
};

export default IndustrySpaces;
