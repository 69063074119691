interface Logo {
  id: string;
  url: string;
  alt: string;
}

export const logos: Logo[] = [
  {
    id: "7-Eleven",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2F7-eleven-6-logo-black-and-white%201.png?alt=media&token=fb6ef321-5e0f-4204-a1b6-15788da1eeb4",
    alt: "7-Eleven Logo",
  },
  {
    id: "Autozone",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FAutoZone_Logo_3%201.png?alt=media&token=9f989d2a-84a0-418b-97c8-a43505dec58b",
    alt: "Autozone Logo",
  },
  {
    id: "Colliers Engineering",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FColliers_White_Web%201.png?alt=media&token=d607719d-cdd1-4f06-96d1-8e496c8bae49",
    alt: "Colliers Engineering Logo",
  },
  {
    id: "Kroger",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FDaco_4330772%201.png?alt=media&token=00df701e-1b93-4dc8-a66a-f1aaf744e434",
    alt: "Kroger Logo",
  },
  {
    id: "JLL",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FJLL_Logo_White%201.png?alt=media&token=73b8fd9b-86c9-4493-a034-997b3fc20ead",
    alt: "JLL Logo",
  },
  {
    id: "Ross",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FRoss%201.png?alt=media&token=94b72eb2-a2f5-4515-90ba-871a33a13a13",
    alt: "Ross Logo",
  },
  {
    id: "Gopuff",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2Fgo-puff-logo%201.png?alt=media&token=518783b2-4f2d-4063-b6df-ea6b843d5ef9",
    alt: "Gopuff Logo",
  },
  {
    id: "Burlington",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2Fburlington%201.png?alt=media&token=e05f36f1-83c4-4de6-9f8f-e32e26a11fce",
    alt: "Burlington Logo",
  },
  {
    id: "Mavis Tires",
    url: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2Fmavis-white%201.png?alt=media&token=8087b7c2-d3e1-445f-a551-1cccc8f3357e",
    alt: "Mavis Tires Logo",
  },
  {
    id: 'Nelson Worldwide',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FDesign.png?alt=media&token=19ffc1e2-5f88-4087-8ce7-79e2bf0733c6',
    alt: 'Nelson Worldwide Logo'
  },
  {
    id: 'TravelCenters of America',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FTravelCenters_of_America_logo%20(1)%201.png?alt=media&token=ba105005-90e7-4587-a4f8-c86ad0448a6a',
    alt: 'TravelCenters of America Logo'
  },
  {
    id: 'Guitar Center',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2Fguitar-center-logo-svg-vector%201.png?alt=media&token=8e551617-f95d-4239-bb66-55d0accea1b5',
    alt: 'Guitar Center Logo'
  },
  {
    id: 'Nordstrom',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2F6090027b5d1f990004970e79%201.png?alt=media&token=fbc63079-0a12-4814-a508-e8d9754d0253',
    alt: 'Nordstrom Logo'
  },
  {
    id: 'Puma',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2F%5BCITYPNG.COM%5DPuma%20White%20Logo%20Image%20PNG%20-%202000x2000%201.png?alt=media&token=b315b5b8-495b-472a-bddc-80c6ccaef21c',
    alt: 'Puma Logo'
  },
  {
    id: 'Speedway',
    url: 'https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FClientCarousel%2FSpeedway_LLC_logo%201.png?alt=media&token=2da411f5-49d8-4e1b-9870-909b3a249857',
    alt: 'Speedway Logo'
  }
];
