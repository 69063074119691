import { styled } from "@mui/material";
import { Link } from "gatsby";

export const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #ffb310;
  width: fit-content;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.2s;
  text-transform: none;
  font-weight: 600;

  &:hover {
    transform: translateY(-2px) scale(1.05);
    background: #ffb310;
    color: white;
  }
`;
