import { IndustrySpace } from "./types";

export const industrySpaces: IndustrySpace[] = [
  {
    id: "retail",
    title: "Retail Commercial Spaces",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Shopping-Bags-Flat.512%201.svg?alt=media&token=0309b4ed-f1e0-460f-9023-0b04d5949787"
  },
  {
    id: "residential",
    title: "Single-Family Residential",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-House-Flat.512%201.svg?alt=media&token=62559ca3-42ad-4628-b40f-79f437021bc4"
  },
  {
    id: "supermarkets",
    title: "Supermarkets",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Shopping-Cart-Flat.512%201.svg?alt=media&token=7a27bc82-527e-4dce-8db6-ce85bae6a95a"
  },
  {
    id: "construction",
    title: "Commercial Construction",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FFrame%2022.svg?alt=media&token=a78fa8bd-f590-44db-a0c2-45744852d071"
  },
  {
    id: "education",
    title: "Educational Institutions",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-School-Flat.512%201.svg?alt=media&token=3f448fe4-259a-40d2-b718-0eb3d9c967e5"
  },
  {
    id: "restaurants",
    title: "Quick Service Restaurants",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Fork-And-Knife-With-Plate-Flat.512%201.svg?alt=media&token=f47f7c11-33ad-45d7-9fca-bfc40d33be6f"
  },
  {
    id: "municipal",
    title: "Municipal Facilities",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Classical-Building-Flat.512%201.svg?alt=media&token=4c114405-b66d-48c5-8fbd-722ac46f1198"
  },
  {
    id: "multi-family",
    title: "Multi-Family Residential",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Houses-Flat.512%201.svg?alt=media&token=45109675-fcd6-48b5-86dc-b0370831ba40"
  },
  {
    id: "office",
    title: "Commercial Office Buildings",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Office-Building-Flat.512%201.svg?alt=media&token=865ddaac-1b60-40fb-8f8f-11adb3c5255b"
  },
  {
    id: "real-estate",
    title: "Real Estate Development",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Department-Store-Flat.512%202.svg?alt=media&token=97d30813-51d8-460f-9513-499a3d7d81b9"
  },
  {
    id: "manufacturing",
    title: "Manufacturing Plants/Industrial Facilities",
    icon: "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2FLanding%2FIndustriesWeServe%2FMicrosoft-Fluentui-Emoji-Flat-Factory-Flat.512%201.svg?alt=media&token=c1a87c70-5631-4f42-a99a-37e5bb663ce8"
  }
];
