import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";
import { breakpoint } from "../../../../components/styled-components/Breakpoints/breakpoints";
const VideoWrapper = styled("div")`
  margin-top: 2rem;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  video {
    width: 100%;
    height: auto;
    display: block;
  }
`;
const AutoplayVideo = () => {
  const [isInView, setIsInView] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      if (isInView) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isInView]);
  return (
    <VideoWrapper>
      <video
        ref={videoRef}
        muted
        playsInline
        loop
        src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FLandingPage%2FRobotic%20Imaging%20Motion%20Graphic%20All%20In%201%20Solution.mp4?alt=media&token=92f7d664-9f47-41da-8345-f6387e89e640"
      />
    </VideoWrapper>
  );
};
export default AutoplayVideo;
