import React from "react";
import {
  StatsWrapper,
  Container,
  StatsCard,
  Header,
  StatsList,
  StatItem,
  IconWrapper,
  StatValue,
  StatLabel,
  ButtonWrapper,
  BigHeader,
  SmallHeader,
} from "./styles";
import { Speed, CalendarMonth, QueryBuilder } from "@mui/icons-material";
import ReusableButton from "../Button/ReusableButton";
import { ArrowForward } from "@mui/icons-material";

const statsData = [
  {
    icon: QueryBuilder,
    value: "2-3",
    label: "Days to Site Capture",
  },
  {
    icon: Speed,
    value: "50-80%",
    label: "Faster than Competitors",
  },
  {
    icon: CalendarMonth,
    value: "10-14",
    label: "Days to CAD Delivery",
  },
];

const StatsSection: React.FC = () => {
  return (
    <StatsWrapper>
      <Container>
        <Header>
          <BigHeader>Where Speed Meets Precision</BigHeader>
          <SmallHeader>
            Efficient data. Faster decisions. Better builds.
          </SmallHeader>
        </Header>
        <StatsCard>
          <StatsList>
            {statsData.map((stat, index) => (
              <StatItem key={index}>
                <IconWrapper>
                  <stat.icon />
                </IconWrapper>
                <StatValue>{stat.value}</StatValue>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            ))}
          </StatsList>

          <ButtonWrapper>
            <ReusableButton
              text="Learn more about us"
              icon={ArrowForward}
              to="/about"
            />
          </ButtonWrapper>
        </StatsCard>
      </Container>
    </StatsWrapper>
  );
};

export default StatsSection;
